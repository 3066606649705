import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout/index'
import Container from '../components/container'

import notFound from '../assets/images/not-found.gif'

const NotFoundPage = () => (
  <Layout>
    <Container className='w-full h-full flex flex-col items-center justify-center'>
      <img src={notFound} className='mb-24 max-w-200' alt='' />
      <h1 className='hidden'>Pagina niet gevonden</h1>
      <p className='text-center '>
        Oh oh, fotomodel niet gevonden!
        <br />
        Zoek ze in mijn{' '}
        <Link to='/portfolio/paarden' className='link'>
          portfolio
        </Link>{' '}
        of{' '}
        <Link to='/contact' className='link'>
          boek een shoot
        </Link>{' '}
        van jouw model.
      </p>
    </Container>
  </Layout>
)

export default NotFoundPage
